<template>
  <div>
   <div
    class="-mt-5 pb-4 bg-white sm:pb-4"
    v-if="
     totalAnswered >= 0 &&
     totalUnanswered >= 0 &&
     this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekDay' &&
     this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekYear'
    ">
    <div class="flex justify-between">
     <div>
      <div class="pt-4 px-4 font-bold">
       {{ title }}
      </div>
      <SwitchGroup as="div" class="flex items-center px-4 pt-2 gap-2">
       <SwitchLabel as="span" class="">
        <span
         :class="[
          isHourlyChart ? 'text-gray-800' : 'text-gray-700',
          'text-sm font-medium ',
         ]"
         >Vue heure</span
        >
       </SwitchLabel>
       <Switch
        v-model="enabled"
        :class="[
         !isHourlyChart ? 'bg-teal-600' : 'bg-teal-600',
         'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-600',
        ]">
        <span
         aria-hidden="true"
         :class="[
          !isHourlyChart ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
         ]" />
       </Switch>
       <SwitchLabel as="span" class="">
        <span
         :class="[
          !isHourlyChart ? 'text-gray-800' : 'text-gray-700',
          'text-sm font-medium ',
         ]"
         >Vue 15 minutes</span
        >
       </SwitchLabel>
      </SwitchGroup>
     </div>
     <!-- <div class="pt-4 font-bold text-gray-800">
       Répondus & non répondus
     </div> -->
     <div class="flex justify-end">
      <div class="max-w-xl px-4 sm:px-6 lg:px-8">
       <div class="max-w-xl mx-auto">
        <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
         <div
          class="flex flex-col border-b border-gray-100 p-1 text-center sm:border-0 sm:border-r">
          <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">
           Total
          </dt>
          <dd class="order-2 text-sm font-extrabold text-gray-900">
           {{ totalAnswered + totalUnanswered }}
          </dd>
         </div>
         <div
          class="flex flex-col border-t border-b border-gray-100 p-1 text-center sm:border-0 sm:border-l sm:border-r">
          <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">
           Répondus
          </dt>
          <dd class="order-2 text-sm font-extrabold text-gray-900">
           {{ totalAnswered }}
           <span class=""
            >({{
             ((totalAnswered / (totalAnswered + totalUnanswered)) * 100).toFixed(
              0
             )
            }}%)</span
           >
          </dd>
         </div>
         <div
          class="flex flex-col border-t border-gray-100 p-1 text-center sm:border-0 sm:border-l">
          <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">
           Non répondus
          </dt>
          <dd class="order-2 text-sm font-extrabold text-gray-900">
           {{ totalUnanswered }} ({{
            (
             (totalUnanswered / (totalAnswered + totalUnanswered)) *
             100
            ).toFixed(0)
           }}%)
          </dd>
         </div>
        </dl>
       </div>
      </div>
     </div>
    </div>
   </div>
   <apexchart
    id="column"
    :width="this.width"
    :height="this.height"
    :options="isHourlyChart ? chartOptionsHour : chartOptions"
    :series="this.seriesForColumn ? this.seriesForColumn : series"></apexchart>
   <div class="pt-4">
    <apexchart
     id="line"
     :width="this.width"
     :height="this.height"
     :options="isHourlyChart ? chartOptionsLineHour : chartOptionsLine"
     :series="
      this.seriesForColumnLine ? this.seriesForColumnLine : series
     "></apexchart>
   </div>
   <div class="flex justify-end text-sm pt-5">
    (1) Appels répondus et non répondus externes. Les appels internes ne sont pas
    pris en compte.
   </div>
   <div class="flex justify-end text-sm pt-1">
    (2) Appels externes répondus uniquement. Les appels Non Répondus et les
    appels internes ne sont pas pris en compte.
   </div>
  </div>
 </template>
 
 <script>
 import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
 
 export default {
  name: "BasicColumnChart",
  components: {
   Switch,
   SwitchGroup,
   SwitchLabel,
  },
  props: [
   "seriesForColumnLine",
   "categoriesForLine",
   "seriesForColumn",
   "categoriesForColumn",
   "width",
   "height",
   "colors",
   "title",
   "totalAnswered",
   "totalUnanswered",
   "isHourlyChart",
  ],
  data() {
   return {
    enabled: true,
    series: [],
    chartOptions: {
     title: {
      text: "Répondus & non répondus¹",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
       fontSize: "14px",
       fontWeight: "bold",
       fontFamily: undefined,
       color: "#263238",
      },
     },
     noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
       color: undefined,
       fontSize: "14px",
       fontFamily: undefined,
      },
     },
 
     colors: ["#16a34a", "#E34B5F", "#1E7889"],
     chart: {
      type: "bar",
     },
     plotOptions: {
      bar: {
       horizontal: false,
       columnWidth: "55%",
       endingShape: "rounded",
      },
     },
     dataLabels: {
      enabled: false,
     },
     legend: {
      show: true,
      position: "top",
     },
     xaxis: {
      categories: this.categoriesForColumn ? this.categoriesForColumn : [],
      labels: {
       rotate: -75,
       rotateAlways: false,
       style: {
        colors: [],
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 500,
        cssClass: "apexcharts-xaxis-label",
       },
       /**
        * Allows users to apply a custom formatter function to x-axis labels.
        *
        * @param { String } value - The default value generated
        * @param { Number } timestamp - In a datetime series, this is the raw timestamp
        * @param { object } contains dateFormatter for datetime x-axis
        */
       formatter: function (value, timestamp, opts) {
        let finalValue = "";
        if (value?.length > 0) {
         let valueFirstTwo = value.slice(0, 2);
         let valueLastTwo = value.slice(-2);
         if (value.length == 4) {
          finalValue = valueFirstTwo + ":" + valueLastTwo;
         } else if (value.length == 2) {
          finalValue = valueFirstTwo + ":" + "00";
         }
        }
        return finalValue;
       },
      },
     },
     yaxis: {
      title: {
       text: "Appels",
      },
      labels: {
       formatter: function (val, data) {
        let index = data ? data.dataPointIndex : undefined;
        let total = undefined;
        if (index >= 0) {
         total = data.series[0][index] + data.series[1][index];
        }
        return val + (total ? ` (${((val / total) * 100).toFixed(0)}%)` : "");
       },
      },
     },
    },
    chartOptionsHour: {
     title: {
      text: "Répondus & non répondus¹",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
       fontSize: "14px",
       fontWeight: "bold",
       fontFamily: undefined,
       color: "#263238",
      },
     },
     noData: {
      text: "No data",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
       color: undefined,
       fontSize: "14px",
       fontFamily: undefined,
      },
     },
 
     colors: ["#16a34a", "#E34B5F", "#1E7889"],
     chart: {
      type: "bar",
     },
     plotOptions: {
      bar: {
       horizontal: false,
       columnWidth: "55%",
       endingShape: "rounded",
      },
     },
     dataLabels: {
      enabled: false,
     },
     legend: {
      show: true,
      position: "top",
     },
     xaxis: {
      categories: this.categoriesForColumn ? this.categoriesForColumn : [],
      labels: {
       rotate: -75,
       rotateAlways: false,
       style: {
        colors: [],
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 500,
        cssClass: "apexcharts-xaxis-label",
       },
       /**
        * Allows users to apply a custom formatter function to x-axis labels.
        *
        * @param { String } value - The default value generated
        * @param { Number } timestamp - In a datetime series, this is the raw timestamp
        * @param { object } contains dateFormatter for datetime x-axis
        */
       formatter: function (value, timestamp, opts) {
        let finalValue = "";
        if (value.length > 0) {
         let valueFirstTwo = value.slice(0, 2);
         let valueLastTwo = value.slice(-2);
         if (value.length == 4) {
          finalValue = valueFirstTwo + ":" + valueLastTwo;
         } else if (value.length == 2) {
          finalValue = valueFirstTwo + ":" + "00";
         }
        }
        if (finalValue.includes(":")) {
         finalValue =
          finalValue.slice(0, 2) +
          "-" +
          (finalValue = "0" + (parseInt(finalValue) + 1)).slice(-2);
         return finalValue + "h";
        }
        return finalValue;
       },
      },
     },
     yaxis: {
      title: {
       text: "Appels",
      },
      labels: {
       formatter: function (val, data) {
        let index = data ? data.dataPointIndex : undefined;
        let total = undefined;
        if (index >= 0) {
         total = data.series[0][index] + data.series[1][index];
        }
        return val + (total ? ` (${((val / total) * 100).toFixed(0)}%)` : "");
       },
      },
     },
    },
    chartOptionsLine: {
     chart: {
      id: "monthly-trends",
      toolbar: {
       show: false,
      },
     },
     xaxis: {
      categories: this.categoriesForLine,
      labels: {
       rotate: -75,
       rotateAlways: false,
       style: {
        colors: [],
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 500,
        cssClass: "apexcharts-xaxis-label",
       },
       /**
        * Allows users to apply a custom formatter function to x-axis labels.
        *
        * @param { String } value - The default value generated
        * @param { Number } timestamp - In a datetime series, this is the raw timestamp
        * @param { object } contains dateFormatter for datetime x-axis
        */
       formatter: function (value, timestamp, opts) {
        let finalValue = "";
        if (value?.length > 0) {
         let valueFirstTwo = value.slice(0, 2);
         let valueLastTwo = value.slice(-2);
         if (value.length == 4) {
          finalValue = valueFirstTwo + ":" + valueLastTwo;
         } else if (value.length == 2) {
          finalValue = valueFirstTwo + ":" + "00";
         }
        }
        return finalValue;
       },
      },
     },
     yaxis: [
      {
       title: {
        text: "Temps (sec)",
       },
       labels: {
        show: true,
        formatter: function (value, timestamp, opts) {
         if (value) {
          if (value > 86400) {
           let quotient = Math.floor(value / 86400);
           let remainder = value % 86400;
           value =
            quotient +
            "." +
            new Date(remainder * 1000).toISOString().substring(11, 19);
          } else {
           value = new Date(value * 1000).toISOString().substring(11, 19);
          }
          return value;
         }
        },
       },
      },
     ],
 
     stroke: {
      curve: "smooth",
     },
     title: {
      text: "Temps d'attente moyen²",
      align: "center",
     },
     legend: {
      position: "top",
     },
     colors: ["#2563eb"],
    },
    chartOptionsLineHour: {
     chart: {
      id: "monthly-trends",
      toolbar: {
       show: false,
      },
     },
     xaxis: {
      categories: this.categoriesForLine,
      labels: {
       rotate: -75,
       rotateAlways: false,
       style: {
        colors: [],
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 500,
        cssClass: "apexcharts-xaxis-label",
       },
       /**
        * Allows users to apply a custom formatter function to x-axis labels.
        *
        * @param { String } value - The default value generated
        * @param { Number } timestamp - In a datetime series, this is the raw timestamp
        * @param { object } contains dateFormatter for datetime x-axis
        */
       formatter: function (value, timestamp, opts) {
        let finalValue = "";
        if (value?.length > 0) {
         let valueFirstTwo = value.slice(0, 2);
         let valueLastTwo = value.slice(-2);
         if (value.length == 4) {
          finalValue = valueFirstTwo + ":" + valueLastTwo;
         } else if (value.length == 2) {
          finalValue = valueFirstTwo + ":" + "00";
         }
        }
        if (finalValue.includes(":")) {
         finalValue =
          finalValue.slice(0, 2) +
          "-" +
          (finalValue = "0" + (parseInt(finalValue) + 1)).slice(-2);
         return finalValue + "h";
        }
        return finalValue;
       },
      },
     },
     yaxis: [
      {
       title: {
        text: "Temps (sec)",
       },
       labels: {
        show: true,
        formatter: function (value, timestamp, opts) {
         if (value) {
          if (value > 86400) {
           let quotient = Math.floor(value / 86400);
           let remainder = value % 86400;
           value =
            quotient +
            "." +
            new Date(remainder * 1000).toISOString().substring(11, 19);
          } else {
           value = new Date(value * 1000).toISOString().substring(11, 19);
          }
          return value;
         }
        },
       },
      },
     ],
 
     stroke: {
      curve: "smooth",
     },
     title: {
      text: "Temps d'attente moyen²",
      align: "center",
     },
     legend: {
      position: "top",
     },
     colors: ["#2563eb"],
    },
   };
  },
  methods: {},
  watch: {
   enabled: function (val) {
    this.$emit("setHourlyChart", val);
   },
  },
  mounted() {
   this.$emit("loading", false);
  },
 };
 </script>
 
 <style></style>
 