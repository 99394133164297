<template>
 <ExpertStatisticsTemplate
  :periodSelector="periodSelector"
  :elements="extensions"
  :clearTimeSelector="clearTimeSelector"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="(activeBanner = true), (key = !key)"
  @successMsg="successMsg = $event"
  @success="isSuccess = $event"
  :urlType="urlType"
  @download-report="$emit('download-report')"
  @setLoading="$emit('setLoading', $event)"
  :pbxElements="pbxElements"
 >
  <template v-slot:selector>
   <PbxDashboardSelector
    @button-selector="(...args) => $emit('button-selector', ...args)"
    @concatenate="(concatenateData = $event), $emit('concatenate', $event)"
    @all-selected="$emit('all-selected', $event)"
    :formatDateToTime="this.formatDateToTime()"
    :concatenateData="concatenateData"
    :elements="extensions"
    :elementsLength="extensions.length"
    :urlType="urlType"
    :buttonSelected="this.buttonSelected"
    @elementToRemove="elementToRemove = $event"
    @timeSelector="$emit('timeSelector', $event)"
    :clearTimeSelector="clearTimeSelector"
    :queryElement="queryElement"
    @loading="isLoading = $event"
    @clearAll="
     (dataColumnChartQuarterHourlySliced = []),
      (dataSeriesCurrentMonthSliced = []),
      (sliceData = 10)
    "
    @selectedDurationMenuItem="selectedDurationMenuItem = $event"
    :pbxElements="pbxElements"
   />
  </template>
  <template v-slot:content>
   <!-- <PbxHeader
    :selectHostname="selectHostname"
    :buttonSelected="buttonSelected"
    :parseDateRange="parseDateRange"
    :parseTimeRange="parseTimeRange"
    :periodSelector="periodSelector"
    :timeSelected="timeSelected"
    :urlType="urlType"
    :pbxMenuType="pbxMenuType"
    :elementToRemove="elementToRemove"
    :elements="extensions"
    @selector="$emit('update:selector', $event)"
    @button-selector="(...args) => $emit('button-selector', ...args)"
    @action="$emit('action', $event)"
    @concatenate="concatenateData = $event"
    @all-selected="$emit('all-selected', $event)"
    @select-hostname="this.selectHostname = $event"
    @select-hostContext="$emit('select-hostContext')"
    :formatDateToTime="this.formatDateToTime()"
    :concatenateData="concatenateData"
    :elementsLength="extensions.length"
    @elementToRemove="elementToRemove = $event"
    @activeBanner="(activeBanner = true), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
    :selections="extensions" /> -->

   <PbxHeader
    :selectHostname="selectHostname"
    :buttonSelected="buttonSelected"
    :parseDateRange="parseDateRange"
    :parseTimeRange="parseTimeRange"
    :periodSelector="periodSelector"
    :timeSelected="timeSelected"
    :urlType="urlType"
    :pbxMenuType="pbxMenuType"
    :elementToRemove="elementToRemove"
    :elements="extensions"
    @selector="$emit('update:selector', $event)"
    @button-selector="(...args) => $emit('button-selector', ...args)"
    @action="$emit('action', $event)"
    @concatenate="concatenateData = $event"
    @all-selected="$emit('all-selected', $event)"
    @select-hostname="this.selectHostname = $event"
    @select-hostContext="$emit('select-hostContext')"
    :formatDateToTime="this.formatDateToTime()"
    :concatenateData="concatenateData"
    :elementsLength="extensions.length"
    @elementToRemove="elementToRemove = $event"
    @activeBanner="(activeBanner = true), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
    :selections="extensions"
    :pbxElements="pbxElements"
   />
   <div class="flex gap-2 justify-between py-4">
    <div
     v-if="$route.name === 'ExpertStatisticsAnsweredUnanswered'"
     class="lg:flex items-center text-center gap-1 w-full lg:w-2/3 border-b border-teal-500"
    >
     <!-- Vue par heure -->
     <button
      @click="selectedAnsweredType = 'hour'"
      :class="[
       selectedAnsweredType === 'hour'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]"
     >
      <span class="text-sm font-bold">{{
       $t("Vue consolidée par heure")
      }}</span>
     </button>

     <!-- Vue par week day -->
     <button
      @click="selectedAnsweredType = 'week'"
      :class="[
       selectedAnsweredType === 'week'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]"
     >
      <span class="text-sm font-bold">{{ $t("Vue consolidée par jour") }}</span>
     </button>

     <!-- Vue par semaine -->
     <button
      @click="selectedAnsweredType = 'day'"
      :class="[
       selectedAnsweredType === 'day'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]"
     >
      <span class="text-sm font-bold">{{ $t("Evolution par jour") }}</span>
     </button>

     <!-- Vue par week -->
     <button
      @click="selectedAnsweredType = 'weekNumber'"
      :class="[
       selectedAnsweredType === 'weekNumber'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]"
     >
      <span class="text-sm font-bold">{{ $t("Evolution par semaine") }}</span>
     </button>

     <!-- Vue par mois -->
     <button
      @click="selectedAnsweredType = 'month'"
      :class="[
       selectedAnsweredType === 'month'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]"
     >
      <span class="text-sm font-bold">{{ $t("Evolution par mois") }}</span>
     </button>
    </div>

    <SwitchGroup
     v-if="$route.name === 'ExpertStatistics'"
     as="div"
     class="flex items-center text-center gap-2"
    >
     <Switch
      v-model="origins_calls_stats_default"
      :class="[
       !origins_calls_stats_default ? 'bg-teal-600' : 'bg-teal-600',
       'relative flex gap-1 text-center h-7 w-52 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      ]"
     >
      <span
       v-if="!origins_calls_stats_default"
       class="text-sm font-bold text-white px-3 py-0.5"
       >{{ $t("Famille d'origine") }}</span
      >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
      <span
       aria-hidden="true"
       :class="[
        origins_calls_stats_default ? 'translate-x-0' : 'translate-x-1 w-16',
        'pointer-events-none  h-6 text-center items-center px-2 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
       ]"
      >
       <SwitchLabel as="span" class="flex cursor-pointer">
        <span
         v-if="!origins_calls_stats_default"
         class="text-sm font-bold text-gray-700"
         >{{ $t("Top 10") }}</span
        >
        <span v-else class="text-sm font-bold text-gray-700">{{
         $t("Famille d'origine")
        }}</span>
        <!-- <span class="text-sm text-gray-500">20s</span> -->
       </SwitchLabel>
      </span>
      <span
       v-if="origins_calls_stats_default"
       class="text-sm font-bold text-white capitalize px-2 py-0.5"
       >{{ $t("top 10") }}</span
      >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
     </Switch>
    </SwitchGroup>
    <SwitchGroup
     v-if="
      $route.name === 'ExpertStatistics' ||
       $route.name === 'ExpertStatisticsAnsweredUnanswered'
     "
     as="div"
     class="flex items-center text-center gap-2"
    >
     <span class="text-sm font-bold text-gray-600">{{
      urlType === "queue"
       ? $t("Afficher les files sans appel")
       : $t("Afficher les utilisateurs sans appel")
     }}</span>
     <Switch
      v-model="origins_calls_stats_filter"
      :class="[
       origins_calls_stats_filter ? 'bg-gray-400' : 'bg-teal-600',
       'relative flex gap-1 text-center h-7 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      ]"
     >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
      <span
       aria-hidden="true"
       :class="[
        origins_calls_stats_filter ? 'translate-x-0' : 'translate-x-5 ',
        'pointer-events-none  h-6 text-center items-center px-2 py-0.5 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
       ]"
      >
       <SwitchLabel as="span" class="flex cursor-pointer">
        <!-- <span
         v-if="origins_calls_stats_filter"
         class="text-sm font-bold text-gray-700"
         >{{ $t("Pas de filtre") }}</span
        >
        <span v-else class="text-sm font-bold text-gray-700">{{
         $t("Afficher tout")
        }}</span> -->
        <!-- <span class="text-sm text-gray-500">20s</span> -->
       </SwitchLabel>
      </span>
      <!-- <span
       v-if="!origins_calls_stats_filter"
       class="text-sm font-bold text-white px-2 py-0.5"
       >{{ $t("Pas de filtre") }}</span
      > -->
      <!-- <span class="text-sm text-gray-500">20s</span> -->
     </Switch>
    </SwitchGroup>
   </div>
   <div class="relative items-stretch">
    <main class="">
     <div class="px-2">
      <div
       v-if="showInfoText && $route.name === 'ExpertStatisticsCallDurations'"
       class="relative bg-blue-200 rounded-lg px-2 py-4 my-2"
      >
       <div class="absolute right-2 top-2">
        <XIcon
         @click="showInfoText = false"
         class="h-4 w-4 text-blue-500 hover:text-blue-600 cursor-pointer"
         aria-hidden="true"
        />
       </div>
       <div class="flex gap-2 items-start text-left">
        <div>
         <InformationCircleIcon
          class="h-6 w-6 text-blue-400"
          aria-hidden="true"
         />
        </div>
        <div class="space-y-1">
         <p class="text-blue-700 text-sm font-medium">
          {{ $t("expertStatsDurationsInfoText") }}
         </p>
         <p class="text-blue-700 text-sm font-medium">
          {{ $t("expertStatsDurationsInfoSubTextOne") }}
         </p>
         <p class="text-blue-700 text-sm font-medium">
          {{ $t("expertStatsDurationsInfoSubTextTwo") }}
         </p>
        </div>
       </div>
      </div>

      <!-- <div
      v-if="
       outgoingCallsData && outgoingCallsData.series && outgoingCallsData.series[0].data.length > 0
      "
     >
      <BasicColumnDashboard
       :key="outgoingCallsData['labels']"
       @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
       :seriesForColumn="outgoingCallsData['series']"
       :categoriesForColumn="outgoingCallsData['labels']"
       :colors="colors"
       @loading="isLoading = $event"
       :showWaitingDuration="showWaitingDuration"
       :title="$t('percentageWaitingTime')"
       @waitingTimeRange="$emit('waitingTimeRange', $event)"
       :waitingTime="waitingTimeRange"
       :selectedDurationMenuItem="selectedDurationMenuItem"
      />
     </div> -->
      <div
       v-if="
        elementLevelDataRange &&
         Object.keys(elementLevelDataRange).length &&
         elementLevelDataRange['labels'] &&
         selectedDurationMenuItem === 'percentageWaitingTime'
       "
       class="space-y-6"
      >
       <BasicColumnDashboard
        :answered_calls_stats_day="answered_calls_stats_day"
        :selectedAnsweredType="selectedAnsweredType"
        :key="elementLevelDataRange['labels']"
        @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
        :seriesForColumn="elementLevelDataRange['series']"
        :categoriesForColumn="elementLevelDataRange['labels']"
        :colors="colors"
        @loading="isLoading = $event"
        :showWaitingDuration="showWaitingDuration"
        :title="$t('percentageWaitingTime')"
        @waitingTimeRange="$emit('waitingTimeRange', $event)"
        :waitingTime="waitingTimeRange"
        :selectedDurationMenuItem="selectedDurationMenuItem"
       />
      </div>
      <div
       v-if="
        elementLevelDataRange &&
         Object.keys(elementLevelDataRange).length &&
         elementLevelDataRange['labels'] &&
         selectedDurationMenuItem === 'percentageWaitingTime' &&
         extensions.length > 0
       "
       class="space-y-6"
      >
       <div v-if="dataElementsDuration && dataElementsDuration.length">
        <div v-for="data in dataElementsDuration" :key="data">
         <BasicColumnDashboard
          :answered_calls_stats_day="answered_calls_stats_day"
          :selectedAnsweredType="selectedAnsweredType"
          :key="data.data.labels"
          :name="data.name"
          @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
          :seriesForColumn="data.data.series"
          :categoriesForColumn="data.data.labels"
          :colors="colors"
          @loading="isLoading = $event"
          :showWaitingDuration="showWaitingDuration"
          :title="$t('avgWaitingTime') + ` - ${data.name}`"
          :totalDuration="data.total"
          @waitingTimeRange="$emit('waitingTimeRange', $event)"
          :waitingTime="waitingTimeRange"
          selectedDurationMenuItem="durationWaitingTime"
         />
        </div>
       </div>
      </div>
      <div
       v-if="
        elementLevelData &&
         Object.keys(elementLevelData).length &&
         elementLevelData['labels'] &&
         selectedDurationMenuItem === 'durationWaitingTime'
       "
       class="space-y-6"
      >
       <BasicColumnDashboard
        :answered_calls_stats_day="answered_calls_stats_day"
        :selectedAnsweredType="selectedAnsweredType"
        :key="elementLevelData['labels']"
        @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
        :seriesForColumn="elementLevelData['series']"
        :categoriesForColumn="elementLevelData['labels']"
        :colors="colors"
        @loading="isLoading = $event"
        :showWaitingDuration="showWaitingDuration"
        :title="$t('durationWaitingTime')"
        @waitingTimeRange="$emit('waitingTimeRange', $event)"
        :waitingTime="waitingTimeRange"
        :selectedDurationMenuItem="selectedDurationMenuItem"
       />
      </div>
      <div
       v-if="
        dataElementsDuration &&
         dataElementsDuration.length &&
         selectedDurationMenuItem === 'durationWaitingTime'
       "
       class="space-y-6"
      >
       <div v-for="data in dataElementsDuration" :key="data">
        <BasicColumnDashboard
         :answered_calls_stats_day="answered_calls_stats_day"
         :selectedAnsweredType="selectedAnsweredType"
         :key="data.data.labels"
         :name="data.name"
         @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
         :seriesForColumn="data.data.series"
         :categoriesForColumn="data.data.labels"
         :colors="colors"
         @loading="isLoading = $event"
         :showWaitingDuration="showWaitingDuration"
         :title="$t('avgCallDuration') + ` - ${data.name}`"
         :totalDuration="data.total"
         @waitingTimeRange="$emit('waitingTimeRange', $event)"
         :waitingTime="waitingTimeRange"
         :selectedDurationMenuItem="selectedDurationMenuItem"
        />
       </div>
      </div>
      <!-- <div
      v-if="dataPbx && dataPbx.data && dataPbx.data.series.length && dataPbx.data.labels.length"
     >
      <BasicColumnDashboard
      :answered_calls_stats_day="answered_calls_stats_day"
      :selectedAnsweredType="selectedAnsweredType"
       :key="dataPbx.data.labels"
       :seriesForColumn="dataPbx.data.series"
       @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
       :categoriesForColumn="dataPbx.data.labels"
       :colors="colors"
       @loading="isLoading = $event"
       :showWaitingDuration="showWaitingDuration"
       :title="$t('avgCallDuration') + ` - ${dataPbx.name}`"
       :totalDuration="dataPbx.total"
      />
     </div> -->
      <div
       v-if="
        concatenateData &&
         dataColumnChartQuarterHourlyCons &&
         dataColumnChartQuarterHourlyCons[0]
       "
       class="space-y-6"
      >
       <div>
        <BasicColumnDashboard
         :answered_calls_stats_day="answered_calls_stats_day"
         :selectedAnsweredType="selectedAnsweredType"
         :key="`${pbxElements}-${isHourlyChart ? 'hourly' : 'quarter'}`"
         v-if="
          dataColumnChartQuarterHourlyCons[0].data &&
           dataColumnChartQuarterHourlyCons[0].labels
         "
         :seriesForColumn="dataColumnChartQuarterHourlyCons[0].data"
         :categoriesForColumn="dataColumnChartQuarterHourlyCons[0].labels"
         :seriesForColumnLine="dataLineChartQuarterHourlyCons[0]?.data"
         :categoriesForLine="dataLineChartQuarterHourlyCons[0]?.labels"
         :colors="colors"
         @loading="isLoading = $event"
         @setHourlyChart="$emit('setHourlyChart', $event)"
         :isHourlyChart="isHourlyChart"
        />
       </div>
      </div>

      <div v-else>
       <div
        v-if="dataColumnChartQuarterHourly"
        :key="combinedKeyForChartUpdate"
        class="space-y-6"
       >
        <div
         v-for="(data, idx) in dataColumnChartQuarterHourlySliced"
         :key="data"
        >
         <BasicColumnDashboard
          :answered_calls_stats_day="answered_calls_stats_day"
          :selectedAnsweredType="selectedAnsweredType"
          :key="pbxElements"
          :seriesForColumn="data.data"
          :categoriesForColumn="data.labels"
          :seriesForColumnLine="dataLineChartQuarterHourly[idx]?.data"
          :categoriesForLine="dataLineChartQuarterHourly[idx]?.labels"
          :colors="colors"
          :title="data.name"
          @loading="isLoading = $event"
          :fullData="data"
          :isHourlyChart="isHourlyChart"
          @setHourlyChart="$emit('setHourlyChart', $event)"
         />
        </div>
        <infinite-loading
         v-if="pbxElements && pbxElements.length > 0"
         @infinite="infiniteHandlerColumnQuarter"
        >
         <template v-slot:no-more>
          {{ $t("noMoreData") }}
         </template>
        </infinite-loading>
       </div>
      </div>
      <!-- <StatsHeading :stats="this.stats" /> -->
      <div
       v-if="
        concatenateData && dataSeriesConcatenated && dataSeriesConcatenated[0]
       "
       class="pt-5"
      >
       <div class="lg:grid gap-2 grid-cols-2">
        <DonutsDashboard
         :key="dataSeriesConcatenated[0]"
         v-show="dataSeriesConcatenated[0].data"
         :data="dataSeriesConcatenated[0].data"
         :title="$t('dashboards.stackedColumnCategories.incomingCount')"
         :colors="this.colors"
         @timeSelector="$emit('timeSelector', $event)"
         :concatenateData="concatenateData"
         @loading="isLoading = $event"
        />
        <!-- <StackedBarChart
            :dataSeries="dataSeriesCurrentMonth"
            :chartCategories="dataLabelsCurrentMonth"
          /> -->
       </div>
      </div>
      <div v-else class="pt-5">
       <div
        :class="[
         showDetail
          ? 'lg:grid-cols-2 xl:grid-cols-2'
          : 'lg:grid-cols-2 xl:grid-cols-2',
         'grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-2 xl:gap-x-4',
        ]"
        :key="combinedKeyForChartUpdate"
        v-if="dataSeriesCurrentMonth"
       >
        <div
         v-for="data in dataSeriesCurrentMonthSliced"
         :key="data"
         @click="
          $route.path.includes('queue-remove-this')
           ? (getQueueDetailsDataObj(new Array(data.name.split(' - ')[0])),
             (showDetail = true),
             (selectedElement = data.name))
           : ''
         "
        >
         <DonutsDashboard
          :data="data.data"
          :title="data.name"
          :colors="this.colors"
          :name="data.name"
          :fullData="data"
          @loading="isLoading = $event"
          @openDetail="
           getQueueDetailsDataObj($event),
            (showDetail = true),
            (selectedElement = $event)
          "
         />
        </div>
        <infinite-loading
         v-if="pbxElements && pbxElements.length > 0"
         @infinite="infiniteHandlerDonut"
        >
         <template v-slot:no-more>
          {{ $t("noMoreData") }}
         </template>
        </infinite-loading>
       </div>
      </div>
     </div>
    </main>
   </div>
  </template>
 </ExpertStatisticsTemplate>
</template>

<script>
import ExpertStatisticsTemplate from "../../views/PBX/ExpertStatisticsTemplate.vue";
// @ is an alias to /src
import axios from "axios";
import DonutsDashboard from "./DonutsDashboard.vue";
import PbxDashboardSelector from "./PbxDashboardSelector.vue";
import StackedBarChart from "../StackedBarChart.vue";
import BasicColumnDashboard from "./BasicColumnDashboard.vue";
import PbxHeader from "./PbxHeader.vue";
import InfiniteLoading from "vue-infinite-loading";

import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const pbxMenuType = "statistics";

export default {
 name: "PbxDashboard",
 props: [
  "buttonSelected",
  "dataLineChartQuarterHourly",
  "dataColumnChartQuarterHourly",
  "dataColumnChartHourly",
  "dataColumnChartQuarterHourlyCons",
  "dataLineChartQuarterHourlyCons",
  "dataColumnChartHourlyCons",
  "dataLabelsCurrentMonth",
  "dataSeriesCurrentMonth",
  "dataSeriesStacked",
  "dataSeriesConcatenated",
  "extensions",
  "periodSelector",
  "textMonth",
  "timeSelected",
  "urlType",
  "queryElement",
  "dataPbx",
  "dataElementsDuration",
  "dataPbxDay",
  "elementLevelData",
  "elementLevelDataRange",
  "waitingTimeRange",
  "outgoingCallsData",
  "startDate",
  "endDate",
  "isHourlyChart",
  "pbxElements",
 ],
 components: {
  DonutsDashboard,
  PbxDashboardSelector,
  StackedBarChart,
  BasicColumnDashboard,
  PbxHeader,
  ExpertStatisticsTemplate,
  Switch,
  SwitchGroup,
  SwitchLabel,
  InfiniteLoading,
 },
 data() {
  return {
   sliceData: 10,
   dataColumnChartQuarterHourlySliced: [],
   dataColumnChartHourlySliced: [],
   dataSeriesCurrentMonthSliced: [],
   delay: 500, // Delay in milliseconds
   visibleChartsColumnQuarter: [],
   visibleChartsColumnHour: [],
   visibleCharts: [], // Keeps track of the charts to be rendered
   selectedAnsweredType: "hour",
   origins_calls_stats_filter: true,
   origins_calls_stats_default: true,
   answered_calls_stats_day: true,
   showInfoText: true,
   selectedDurationMenuItem: "percentageWaitingTime",
   selectedElement: "",
   queueData: [],
   showDetail: false,
   isLoading: false,
   elementToRemove: "",
   pbxMenuType,
   showWaitingDuration: true,
   seriesForColumn: [],
   categoriesForColumn: [],
   elements: [],
   concatenatedChartData: [],
   concatenatedChartDataStacked: [],
   concatenateData: false,
   montlyTotalSeries: [],
   montlyTotalLabels: [],
   totalChartSeries: [],
   totalChartLabels: [],
   pbx3cxData: {},
   callData: [],
   callDataHistory: [],
   callDataParticipants: [],
   callDataSegments: [],
   colors: [
    "#97BAA6",
    "#14233C",
    "#E34B5F",
    "#194E63",
    "#1E7889",
    "#D9B3A3",
    "#EFB0A1",
    "#5B9998",
    "#A5A59D",
    "#CAAB9F",
   ],
   pbxExtensions: [],
   totalExtension: [],
   totalCallQueue: [],
   totalOther: [],
   totalExtensionValue: "",
   pbxQueues: [],
   selectHostname: "",
   stats: [
    {
     name: "Total Extension",
     stat: "1000",
     previousStat: "900",
     change: "10%",
     changeType: "increase",
    },
    {
     name: "Total Callqueue",
     stat: "",
     previousStat: "",
     change: "",
     changeType: "",
    },
    {
     name: "Total Other",
     stat: "",
     previousStat: "",
     change: "",
     changeType: "",
    },
   ],
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
  };
 },
 methods: {
  infiniteHandlerDonut($state) {
   setTimeout(
    function() {
     if (
      this.dataSeriesCurrentMonthSliced.length ==
      this.dataSeriesCurrentMonth.length
     ) {
      $state.complete();
     } else {
      this.dataSeriesCurrentMonthSliced = this.dataSeriesCurrentMonthSliced.concat(
       this.dataSeriesCurrentMonth.slice(this.sliceData - 10, this.sliceData)
      );
      this.sliceData = this.sliceData + 10;
      // this.concatArray();
     }
     $state.loaded();
    }.bind(this),
    1000
   );
  },
  infiniteHandlerColumnQuarter($state) {
   setTimeout(
    function() {
     if (
      this.dataColumnChartQuarterHourlySliced.length ==
      this.dataColumnChartQuarterHourly.length
     ) {
      $state.complete();
     } else {
      this.dataColumnChartQuarterHourlySliced = this.dataColumnChartQuarterHourlySliced.concat(
       this.dataColumnChartQuarterHourly.slice(
        this.sliceData - 10,
        this.sliceData
       )
      );
      this.sliceData = this.sliceData + 10;
      // this.concatArray();
     }
     $state.loaded();
    }.bind(this),
    1000
   );
  },
  infiniteHandlerColumnHour($state) {
   setTimeout(
    function() {
     if (
      this.dataColumnChartHourlySliced.length ==
      this.dataColumnChartHourly.length
     ) {
      $state.complete();
     } else {
      this.dataColumnChartHourlySliced = this.dataColumnChartHourlySliced.concat(
       this.dataColumnChartHourly.slice(this.sliceData - 10, this.sliceData)
      );
      this.sliceData = this.sliceData + 10;
      // this.concatArray();
     }
     $state.loaded();
    }.bind(this),
    1000
   );
  },
  infiniteHandlerColumnDay($state) {
   setTimeout(
    function() {
     if (
      this.dataColumnChartQuarterHourlySliced.length ==
      this.dataColumnChartQuarterHourly.length
     ) {
      $state.complete();
     } else {
      this.dataColumnChartQuarterHourlySliced = this.dataColumnChartQuarterHourlySliced.concat(
       this.dataColumnChartQuarterHourly.slice(
        this.sliceData - 20,
        this.sliceData
       )
      );
      this.sliceData = this.sliceData + 20;
      // this.concatArray();
     }
     $state.loaded();
    }.bind(this),
    1000
   );
  },
  setElements(event) {
   this.elements = [];
   for (let index = 0; index < event.length; index++) {
    this.elements.push(event[index].label);
   }
  },

  // concatenateChartData(event) {
  //   if (this.dataSeriesCurrentMonth.length) {
  //     let arrayBeforeConcatenate = [];
  //     for (
  //       let index = 0;
  //       index < this.dataSeriesCurrentMonth.length;
  //       index++
  //     ) {
  //       arrayBeforeConcatenate.push(this.dataSeriesCurrentMonth[index].data);
  //     }
  //     this.concatenatedChartData = this.sumArrays(arrayBeforeConcatenate);
  //   }
  // },
  sumArrays(array) {
   if (array.length > 1) {
    let summedArray = [];
    let summedArrayElement0 = array[0][0];
    let summedArrayElement1 = array[0][1];
    let summedArrayElement2 = array[0][2];
    for (let index = 0; index < array.length - 1; index++) {
     summedArrayElement0 = summedArrayElement0 + array[index + 1][0];
     summedArrayElement1 = summedArrayElement1 + array[index + 1][1];
     summedArrayElement2 = summedArrayElement2 + array[index + 1][2];
    }
    summedArray.splice(0, 0, summedArrayElement0);
    summedArray.splice(1, 0, summedArrayElement1);
    summedArray.splice(2, 0, summedArrayElement2);
    return summedArray;
   }
  },
  sortDateTime(data) {
   data.sort(function(a, b) {
    return new Date(a) - new Date(b);
   });
  },
  formatDateToTime(date) {
   if (date) {
    let hours = ("0" + new Date(date).getHours()).slice(-2);
    let minutes = ("0" + new Date(date).getMinutes()).slice(-2);
    let seconds = ("0" + new Date(date).getSeconds()).slice(-2);
    return hours + ":" + minutes + ":" + seconds;
   } else {
    return "null";
   }
  },
  getDataForBasicColumn(data) {
   if (data) {
    let keys = Object.keys(data);
    let keyNames = [];
    keyNames.push(keys[0], keys[1]);
    let obj = {};
    for (let index = 0; index < keyNames.length; index++) {
     const element = keyNames[index];
     obj["name"] = element;
     obj["data"] = data[element];
     this.seriesForColumn.push(obj);
    }
    this.categoriesForColumn = data.labels;
   }
  },

  async getPbx3cxData() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbx3cx/3/origins`
    );
    this.pbx3cxData = res.data;
    let keys = Object.keys(res.data);
    let extensions = [];
    let queues = [];
    for (let index = 0; index < keys.length; index++) {
     if (keys[index].includes("extension")) {
      let spliceKey = keys[index].split(":");
      extensions.push(spliceKey[1]);
     }
     let spliceQueueKey = keys[index].split(":");
     if (spliceQueueKey[0].includes("queue")) {
      let spliceKey = keys[index].split(":");
      queues.push(spliceKey[1]);
     }
    }
    this.pbxExtensions = extensions.filter(function(item, pos, self) {
     return self.indexOf(item) == pos;
    });
    this.pbxQueues = queues.filter(function(item, pos, self) {
     return self.indexOf(item) == pos;
    });
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getMontlyPbxDataForExtensionOrQueue(numb) {
   let data = await this.getPbx3cxData();
   let keys = Object.keys(data);
   keys.forEach((element, index) => {
    let spliceKey = keys[index].split(":");
    if (keys[index].includes("extension") && spliceKey[2].length === 6) {
     this.totalExtension.push(parseInt(data[keys[index]]));
    }
    let spliceQueueKey = keys[index].split(":");
    if (keys[index].includes("queue") && spliceQueueKey[2].length === 6) {
     this.totalCallQueue.push(parseInt(data[keys[index]]));
    }
    let spliceOtherKey = keys[index].split(":");
    if (keys[index].includes("other") && spliceOtherKey[2].length === 6) {
     this.totalOther.push(parseInt(data[keys[index]]));
    }
   });
   this.getTotal();
   // let montlyTotal = undefined;
   for (let index = 0; index < keys.length; index++) {
    let spliceQueueKey = keys[index].split(":");
    if (
     numb === spliceQueueKey[1] &&
     spliceQueueKey[2] === this.currentMonthAndYearForPbx
    ) {
     this.montlyTotal = data[keys[index]];
    }
   }
  },
  getTotal() {
   const add = arr => arr.reduce((a, b) => a + b, 0);
   this.stats[0].stat = add(this.totalExtension);
   this.stats[1].stat = add(this.totalCallQueue);
   this.stats[2].stat = add(this.totalOther);
   for (let index = 0; index < this.stats.length; index++) {
    this.totalChartSeries.push(this.stats[index].stat);
    this.totalChartLabels.push(this.stats[index].name);
   }
   this.stats[0].previousStat = 0;
   this.stats[1].previousStat = 0;
   this.stats[2].previousStat = 0;
   this.stats[0].change = "0%";
   this.stats[1].change = "0%";
   this.stats[2].change = "0%";
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    if (this.textMonth) {
     fullDay = this.textMonth + " - " + year;
    } else {
     fullDay = month + "/" + year;
    }
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
 },
 mounted() {
  // this.getPbx3cxData();
 },
 computed: {
  currentMonthAndYearForPbx() {
   let month = new Date().getMonth() + 1;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  combinedKeyForChartUpdate() {
   return `${this.origins_calls_stats_filter}-${this.origins_calls_stats_default}-${this.isHourlyChart}`; // Combine keys into a unique string
  },
 },

 watch: {
  dataSeriesCurrentMonth: function(val) {
   if (val.length) {
    this.getDataForBasicColumn(val[0]);
   }
  },
  answered_calls_stats_day: function(val) {
   this.$emit("is_daily", val);
  },
  origins_calls_stats_default: function(val) {
   this.dataSeriesCurrentMonthSliced = [];
   this.sliceData = 10;
   this.$emit("is_default", val);
  },
  origins_calls_stats_filter: function(val) {
   this.dataColumnChartQuarterHourlySliced = [];
   this.dataSeriesCurrentMonthSliced = [];
   this.dataColumnChartHourlySliced = [];
   this.sliceData = 10;
   this.$emit("is_filtered", val);
  },
  isHourlyChart: function(val) {
   this.dataColumnChartQuarterHourlySliced = [];
   this.dataSeriesCurrentMonthSliced = [];
   this.dataColumnChartHourlySliced = [];
   this.sliceData = 10;
  },
  selectedAnsweredType: function(val) {
   this.dataColumnChartQuarterHourlySliced = [];
   this.dataSeriesCurrentMonthSliced = [];
   this.dataColumnChartHourlySliced = [];
   this.sliceData = 10;
   this.concatenateData = true;
   this.$emit("concatenate", true);
   this.$emit("selected_answered_type", val);
  },
 },
};
</script>

<style></style>
