<template>
 <div class="lg:mx-4">
  
  <div
   class="lg:flex lg:justify-between items-center text-center lg:ml-4 px-2 pt-6">
   <div class="lg:w-1/3 lg:-mt-10">
    <HoverDatePicker
     :periodSelector="periodSelector"
     @timeSelector="$emit('timeSelector', $event)"
     :elements="elements"
     :clearTimeSelector="clearTimeSelector"
     @selector="
      $emit('update:selector', $event),
       $emit('setSelector', $event),
       $store.dispatch('pbxPeriodSelected', $event)
     "
     @setPeriod="
      (...args) => {
       $emit('setPeriod', ...args);
      }
     "
     :startDate="startDate"
     :endDate="endDate" />
   </div>
   <div class="mt-14 lg:mt-0">
    <slot name="selector"></slot>
   </div>
  </div>
  <div class="max-w-full mx-auto py-4">
   <div
    class="lg:grid lg:grid-cols-4 lg:gap-8 sm:space-y-0 lg:space-y-0 lg:mr-2 lg:ml-6">
    <div class="col-span-4 space-y-2 pt-2 pb-12 bg-white px-2 rounded-sm">
     <div>
      <slot name="content"></slot>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import HoverDatePicker from "../../components/PBX/HoverDatePicker.vue";
import axios from "axios";
import {
 Dialog,
 DialogPanel,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import { mapGetters } from "vuex";
export default {
 name: "ExpertStatiticsTemplate",
 props: [
  "periodSelector",
  "elements",
  "clearTimeSelector",
  "startDate",
  "endDate",
  "urlType",
 ],
 components: {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  HoverDatePicker,
 },
 data() {
  return {
   reportList: false,
   thead: [],
   tbody: [],
   dataReports: null,
  };
 },
 methods: {
  async deleteItem(
   id,
   title,
   confirmationMessage,
   confirmButton,
   goBack,
   successMsg
  ) {
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports/${id}`;
   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   if (r) {
    this.$emit("setLoading", true);
    axios
     .delete(url, {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("apiToken")}`,
      },
     })
     .then(res => {
      if (res) {
       // this.sendMessage(successMsg);
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
       this.getReportList();
      }
     })
     .catch(error => {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
   this.$emit("setLoading", false);
  },
  async getReportList() {
   this.$emit("setLoading", true);
   let reportType = this.getReportType();
   let elementType = this.urlType ? (this.urlType === "queue" ? 4 : 0) : "*";
   let url = `${localStorage.getItem("apiUrl")}/api/v1.2/${
    this.hostName
   }/reports?page=${
    this.page
   }&filter[report_type]=${reportType}&filter[element_type]=${elementType}`;
   try {
    const res = await axios.get(url, {
     headers: {
      Authorization: "Bearer " + localStorage.getItem("apiToken"),
     },
    });
    this.dataReports = res.data;

    if (res.data && res.data.data.length > 0) {
     const keyArr = Object.keys(res.data.data[0]);
     const excludedValues = [
      "name",
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      // "repeat_pattern",
      // "report_type",
      "dns",
      "instant",
      "email",
      "email_sender",
     ];
     const excludedValuesHead = [
      "name",
      "host_name",
      "created_at",
      "updated_at",
      "element_type",
      "start_at",
      // "repeat_pattern",
      "report_type",
      "dns",
      "instant",
      "email",
      "email_sender",
     ];
     this.thead = keyArr
      .filter(name => !excludedValuesHead.includes(name))
      .map(name => ({ name, sort: "name", isSort: false }));

     this.tbody = res.data.data.map(obj => {
      const filteredObj = Object.fromEntries(
       Object.entries(obj).filter(([key]) => !excludedValues.includes(key))
      );
      return filteredObj;
     });
    }
   } catch (error) {
    this.errorHandling(error);
   } finally {
   }
   this.$emit("setLoading", false);
  },
  getReportType() {
   let routeName = this.$route.name;
   let type = null;

   switch (routeName) {
    case "ExpertStatistics":
     type = "origins";
     break;
    case "ExpertStatisticsOriginDetail":
     type = "originDetails";
     break;
    case "ExpertStatisticsAnsweredUnanswered":
     type = "answered";
     break;
    case "ExpertStatisticsAnsweredDaily":
     type = "answeredDaily";
     break;
    case "ExpertStatisticsQueueDetail":
     type = "queueDetails";
     break;
    case "ExpertStatisticsFullQueueReport":
     type = "report";
     break;
    case "ExpertStatisticsQueueComparator":
     type = "queuesComparison";
     break;
    case "Dashboard":
     type = "dashboard";
     break;
   }

   return type;
  },
 },
 mounted() {
  // this.getReportList();
 },
 computed: {
  ...mapGetters(["hostName"]),
 },
};
</script>

<style></style>
