<template>
    <div>
     <div
      class="-mt-5 pb-4 bg-white sm:pb-4"
      v-if="
       totalAnswered >= 0 &&
       totalUnanswered >= 0 &&
       this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekDay' &&
       this.$route.name !== 'ExpertStatisticsAnsweredUnansweredWeekYear'
      ">
      <div class="flex justify-between">
       <div class="pt-4 px-4 font-bold">
        {{ title }}
       </div>
       <div class="max-w-xl px-4 sm:px-6 lg:px-8">
        <div class="max-w-xl mx-auto">
         <dl class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
          <div
           class="flex flex-col border-b border-gray-100 p-1 text-center sm:border-0 sm:border-r">
           <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">
            Total
           </dt>
           <dd class="order-2 text-sm font-extrabold text-gray-900">
            {{ totalAnswered + totalUnanswered }}
           </dd>
          </div>
          <div
           class="flex flex-col border-t border-b border-gray-100 p-1 text-center sm:border-0 sm:border-l sm:border-r">
           <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">
            Répondus
           </dt>
           <dd class="order-2 text-sm font-extrabold text-gray-900">
            {{ totalAnswered }}
            <span class=""
             >({{
              ((totalAnswered / (totalAnswered + totalUnanswered)) * 100).toFixed(
               0
              )
             }}%)</span
            >
           </dd>
          </div>
          <div
           class="flex flex-col border-t border-gray-100 p-1 text-center sm:border-0 sm:border-l">
           <dt class="order-1 mt-0 text-sm leading-6 font-medium text-gray-500">
            Non répondus
           </dt>
           <dd class="order-2 text-sm font-extrabold text-gray-900">
            {{ totalUnanswered }} ({{
             ((totalUnanswered / (totalAnswered + totalUnanswered)) * 100).toFixed(
              0
             )
            }}%)
           </dd>
          </div>
         </dl>
        </div>
       </div>
      </div>
     </div>
     <apexchart
      :width="this.width"
      :height="this.height"
      :options="chartOptions"
      :series="this.seriesForColumn ? this.seriesForColumn : series"></apexchart>
     <div class="pt-4">
      <apexchart
       id="line"
       :width="this.width"
       :height="this.height"
       :options="chartOptionsLine"
       :series="
        this.seriesForColumnLine ? this.seriesForColumnLine : series
       "></apexchart>
     </div>
     <div class="flex justify-end text-sm pt-5">
      (1) Appels répondus et non répondus externes. Les appels internes ne sont pas
      pris en compte.
     </div>
     <div class="flex justify-end text-sm pt-1">
      (2) Appels externes répondus uniquement. Les appels Non Répondus et les
      appels internes ne sont pas pris en compte.
     </div>
    </div>
   </template>
   
   <script>
   export default {
    name: "BasicColumnChart",
    props: [
     "seriesForColumnLine",
     "categoriesForLine",
     "seriesForColumn",
     "categoriesForColumn",
     "width",
     "height",
     "colors",
     "title",
     "totalAnswered",
     "totalUnanswered",
    ],
    data() {
     return {
      series: [],
      chartOptions: {
       title: {
        text: "Répondus & non répondus¹",
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
         fontSize: "14px",
         fontWeight: "bold",
         fontFamily: undefined,
         color: "#263238",
        },
       },
       noData: {
        text: "No data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
         color: undefined,
         fontSize: "14px",
         fontFamily: undefined,
        },
       },
   
       colors: ["#16a34a", "#E34B5F", "#1E7889"],
       chart: {
        defaultLocale: "fr",
        locales: [
         {
          name: "fr",
          options: {
           months: [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
           ],
           shortMonths: [
            "Janv",
            "Févr",
            "Mars",
            "Avr",
            "Mai",
            "Juin",
            "Juil",
            "Août",
            "Sept",
            "Oct",
            "Nov",
            "Déc",
           ],
           days: [
            "Dimanche",
            "Lundi",
            "Mardi",
            "Mercredi",
            "Jeudi",
            "Vendredi",
            "Samedi",
           ],
           shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
           toolbar: {
            download: "Télécharger SVG",
            selection: "Sélection",
            selectionZoom: "Zoom de sélection",
            zoomIn: "Zoom avant",
            zoomOut: "Zoom arrière",
            pan: "Panoramique",
            reset: "Réinitialiser le zoom",
           },
          },
         },
         {
          name: "en",
          options: {
           months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
           ],
           shortMonths: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
           ],
           days: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
           ],
           shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
           toolbar: {
            download: "Download SVG",
            selection: "Selection",
            selectionZoom: "Selection Zoom",
            zoomIn: "Zoom In",
            zoomOut: "Zoom Out",
            pan: "Panning",
            reset: "Reset Zoom",
           },
          },
         },
        ],
        type: "bar",
       },
       plotOptions: {
        bar: {
         horizontal: false,
         columnWidth: "55%",
         endingShape: "rounded",
        },
       },
       dataLabels: {
        enabled: false,
       },
       legend: {
        show: true,
        position: "top",
       },
       xaxis: {
        categories: this.categoriesForColumn ? this.categoriesForColumn : [],
        tickPlacement: "on",
        tickAmount: 31,
        labels: {
         rotate: -75,
         rotateAlways: false,
         style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
         },
         /**
          * Allows users to apply a custom formatter function to x-axis labels.
          *
          * @param { String } value - The default value generated
          * @param { Number } timestamp - In a datetime series, this is the raw timestamp
          * @param { object } contains dateFormatter for datetime x-axis
          */
         formatter: function (value, timestamp, opts) {
          let finalValue = "";
          if (value?.length > 0 && value?.length > 6 && parseInt(value) >= 0) {
           let newDate = new Date(value);
           let year = newDate.getFullYear();
           let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
           let day = ("0" + newDate.getDate()).slice(-2);
           let formated = String(day) + "/" + String(month) + "/" + String(year);
           return formated;
          }
          if (value?.length == 6 && parseInt(value) >= 0) {
           // Extract year and month
           let year = value.slice(0, 4); // Get the year
           let monthIndex = value.slice(-2); // Get the month index as a string
           let month = parseInt(monthIndex, 10); // Convert to integer
   
           // Array of month names in French
           const monthsInFrench = [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
           ];
   
           // Get the month name
           let monthName = monthsInFrench[month - 1]; // Subtract 1 because array is 0-indexed
   
           // Format the output
           let formatted = `${monthName} ${year}`;
   
           return formatted;
          }
   
          if (value?.length == 2) {
           let formated = "semaine " + value;
           return formated;
          }
   
          return value;
         },
        },
       },
       yaxis: {
        title: {
         text: "Appels",
        },
        labels: {
         formatter: function (val, data) {
          let index = data ? data.dataPointIndex : undefined;
          let total = undefined;
          if (index >= 0) {
           total = data.series[0][index] + data.series[1][index];
          }
          return val + (total ? ` (${((val / total) * 100).toFixed(0)}%)` : "");
         },
        },
       },
      },
      chartOptionsLine: {
       chart: {
        id: "monthly-trends",
        toolbar: {
         show: false,
        },
       },
       xaxis: {
        categories: this.categoriesForLine,
        tickPlacement: "on",
        tickAmount: 31,
        labels: {
         rotate: -75,
         rotateAlways: false,
         style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 500,
          cssClass: "apexcharts-xaxis-label",
         },
         /**
          * Allows users to apply a custom formatter function to x-axis labels.
          *
          * @param { String } value - The default value generated
          * @param { Number } timestamp - In a datetime series, this is the raw timestamp
          * @param { object } contains dateFormatter for datetime x-axis
          */
         formatter: function (value, timestamp, opts) {
          let finalValue = "";
          if (value?.length > 0 && value?.length > 6 && parseInt(value) >= 0) {
           let newDate = new Date(value);
           let year = newDate.getFullYear();
           let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
           let day = ("0" + newDate.getDate()).slice(-2);
           let formated = String(day) + "/" + String(month) + "/" + String(year);
           return formated;
          }
          if (value?.length == 6 && parseInt(value) >= 0) {
           // Extract year and month
           let year = value.slice(0, 4); // Get the year
           let monthIndex = value.slice(-2); // Get the month index as a string
           let month = parseInt(monthIndex, 10); // Convert to integer
   
           // Array of month names in French
           const monthsInFrench = [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
           ];
   
           // Get the month name
           let monthName = monthsInFrench[month - 1]; // Subtract 1 because array is 0-indexed
   
           // Format the output
           let formatted = `${monthName} ${year}`;
   
           return formatted;
          }
   
          if (value?.length == 2) {
           let formated = "semaine " + value;
           return formated;
          }
   
          return value;
         },
        },
       },
       yaxis: [
        {
         title: {
          text: "Temps (sec)",
         },
         labels: {
          show: true,
          formatter: function (value, timestamp, opts) {
           if (value) {
            if (value > 86400) {
             let quotient = Math.floor(value / 86400);
             let remainder = value % 86400;
             value =
              quotient +
              "." +
              new Date(remainder * 1000).toISOString().substring(11, 19);
            } else {
             value = new Date(value * 1000).toISOString().substring(11, 19);
            }
            return value;
           } else {
            return "no appels";
           }
          },
         },
        },
       ],
   
       stroke: {
        curve: "smooth",
       },
       title: {
        text: "Temps d'attente moyen²",
        align: "center",
       },
       legend: {
        position: "top",
       },
       colors: ["#2563eb"],
      },
     };
    },
    methods: {},
    mounted() {
     this.$emit("loading", false);
    },
   };
   </script>
   
   <style></style>
   